import styled from 'styled-components';

const SolidButtonStyle = styled.button`
  background-color: #614ca4;
  color: white;
  cursor: pointer;
  height: 54px;
  // width: 11rem;
  width: 256px;
  border-radius: 50px;
  font-size: 18px;
  font-family: 'Manrope';
  font-weight: bold;
  outline: none;
  border: none;
  transition: 0.3s ease-out;
  padding-left: 24px;
  padding-right: 24px;

  @media (max-width: 600px) {
    font-size: 0.8rem !important;
    height: 2.5rem;
  }
`;

const OutlineButtonStyle = styled(SolidButtonStyle)`
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #834d9b, #ffc6c5) border-box;
  border-radius: 50em;
  border: 3px solid transparent;
  color: black;
  width: 212px;

  // position: relative;
  // display: inline-flex;
  // justify-content: center;
  // align-items: center;
  // font-size: 1em;
  // color: darkblue;
  // padding: 0.5rem 1rem;
  // cursor: pointer;
`;

export { SolidButtonStyle, OutlineButtonStyle };
