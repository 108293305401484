import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

const GlobalStyles = createGlobalStyle`
    html {

        background: white;
    }
    *, *:before, *:after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /* -webkit-transition: all 0.30s ease-in-out;
        -moz-transition: all 0.30s ease-in-out;
        -ms-transition: all 0.30s ease-in-out;
        -o-transition: all 0.30s ease-in-out; */
    }
    a {
        text-decoration: none;
      }
  
    body {
        background: white;
        transition: all 0.05s linear;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    input::-webkit-input-placeholder {
        color: #BDBDBD;
      }
      input:-moz-placeholder {
        color: #BDBDBD;
        opacity: 1;
      }
      input::-moz-placeholder {
        color: #BDBDBD;
        opacity: 1;
      }
      input:-ms-input-placeholder {
        color: #BDBDBD;
      }
      input::-ms-input-placeholder {
        color: #BDBDBD;
      }
`;

export { GlobalStyles };
