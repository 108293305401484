import styled from 'styled-components';

const TextfieldStyle = styled.input`
  height: 3.1rem;
  width: 100%;
  color: #bdbdbd;
  background-color: #e7e7e7;
  border-radius: 20px;
  border: 0px solid transparent;

  font-family: 'Manrope';

  padding-right: 5px;
  outline: 0;
  text-indent: 16px;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.73px;
  text-align: center;

  &:focus {
    border: 1.5px solid rgba(71, 71, 71, 0.17);
  }

  @media (max-width: 600px) {
    font-size: 0.8rem !important;
    height: 2.6rem;
    text-indent: 10px;
  }
`;

export { TextfieldStyle };
