import Facebook from '../assets/icons/facebook.svg';
import Instagram from '../assets/icons/instagram.svg';
import Twitter from '../assets/icons/twitter.svg';
import LinkedIn from '../assets/icons/linkedin.svg';
import Logo from '../assets/icons/logo.svg';
import Check from '../assets/icons/check.svg';
import AppStyle from './style';
import React from 'react';
import Spacer from '../utils/spacer';
import styled from 'styled-components';
import { OutlineButton, SolidButton } from '../components/Button';
import Textfield from '../components/TextInput';

const Home = () => {
  return (
    <AppStyle>
      <div className="header">
        <h1>
          Open your US
          <br />
          account before
          <br />
          arriving
        </h1>
        <img src={Logo} />
      </div>
      <div className="mobile-header">
        <img src={Logo} />
        <h1>
          Open your <br /> US account <br /> before
          <br />
          arriving
        </h1>
      </div>
      <Spacer axis="vertical" size={75} />
      <div className="flex-container-2">
        <div className="left-container">
          <h1>Q4</h1>
          <h5>Available This November - Euphoria App</h5>
          <Spacer axis="vertical" size={10} />
          <div className="text-box">
            <p>
              Sign up <span>before or after</span> you arrive with your US or
              non-US address and phone number. Enjoy access to perks like
              funding your account with your local currency,{' '}
              <span> buy a sim card </span>
              and lots more.
            </p>
          </div>
          <Spacer axis="vertical" size={38} />
          <div className="button-class">
            <Textfield label="Join 10,471 Travellers" />

            <Spacer axis="vertical" size={28} />

            <SolidButton label="Join Waitlist" />
          </div>
        </div>
        <div className="right-container">
          <h1>Ready to join? Here's what you need:</h1>
          <Spacer axis="vertical" size={36} />
          <div className="requirements-container">
            <img src={Check} />
            <Spacer axis="horizontal" size={29} />
            <h6>A Valid ID and a selfie</h6>
          </div>
          <Spacer axis="vertical" size={18} />
          <div className="requirements-container">
            <img src={Check} />
            <Spacer axis="horizontal" size={29} />
            <h6>SSN or ITIN</h6>
          </div>
          <Spacer axis="vertical" size={18} />
          <div className="requirements-container">
            <img src={Check} />
            <Spacer axis="horizontal" size={29} />
            <h6>Residential address</h6>
          </div>
          <Spacer axis="vertical" size={18} />
          <div className="requirements-container">
            <img src={Check} />
            <Spacer axis="horizontal" size={29} />
            <h6>Phone number</h6>
          </div>
          <Spacer axis="vertical" size={18} />
          <div className="requirements-container">
            <img src={Check} />
            <Spacer axis="horizontal" size={29} />
            <h6>Must be 16 or older</h6>
          </div>{' '}
          <Spacer axis="vertical" size={43} />
          <div className="button-class">
            {/* whatsapp://send?phone=+2349050758239&text=I need help! */}
            <OutlineButton label="Talk to us" />
          </div>
          <Spacer axis="vertical" size={45} />
          <hr />
        </div>
      </div>

      <Spacer axis="vertical" size={19} />
      <div className="footer">
        <div className="social-container">
          <a
            href="https://www.facebook.com/splishpay/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Facebook} />
          </a>
          <Spacer axis="horizontal" size={16} />
          <a
            href="https://twitter.com/splishpay/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Twitter} />
          </a>
          <Spacer axis="horizontal" size={16} />
          <a
            href="https://www.instagram.com/splishpay/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Instagram} />
          </a>
          <Spacer axis="horizontal" size={16} />
          <a
            href="https://www.linkedin.com/company/splishpay/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LinkedIn} />
          </a>
        </div>
      </div>
    </AppStyle>
  );
};

export default Home;
