import { GlobalStyles } from './Global';
import Home from '../pages';

function App() {
  return (
    <>
      <GlobalStyles />
      <Home />
    </>
  );
}

export default App;
