import styled from 'styled-components';

const AppStyle = styled.div`
  height: 100vh;
  width: 100vw;
  // background: url(/img/bg_image.webp), lightgray;
  // background-position: right center, center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;

  & .header {
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;

    display: flex;
    justify-content: space-between;
    align-items: start;

    h1 {
      font-size: 120px;
      letter-spacing: -6px;
      line-height: 1;
    }

    @media only screen and (max-width: 600px) {
      display: none !important;
    }
  }

  & .mobile-header {
    display: none;
    margin-left: 20px;
    margin-right: 20px;
    align-items: start;
    justify-content: space-between;

    h1 {
      font-size: 55px;
      margin-top: 80px;
      font-weight: 700;
      text-align: end;
      letter-spacing: -6px;
      line-height: 1.1;
    }

    img {
      width: 70px;
      height: 70px;
    }

    @media only screen and (max-width: 600px) {
      display: flex !important;
    }
  }

  & .flex-container-2 {
    display: flex;
    align-items: start;
    width: 100%;

    & .left-container {
      background-color: transparent;
      width: 50%;
      margin-left: 40px;

      h1 {
        font-size: 130px;
        color: #614ca4;
      }

      h5 {
        font-size: 20px;
        color: #0b1f46;
      }

      & .button-class {
        width: 64%;
        text-align: center;

        @media only screen and (max-width: 600px) {
          width: 100%;
        }
      }

      & .text-box {
        width: 366px;
        p {
          font-size: 17px;
          color: #0b1f46;

          span {
            font-weight: 700;
          }
        }
      }

      @media only screen and (max-width: 600px) {
        width: 100%;
        margin-left: 0;
      }
    }

    & .right-container {
      background-color: transparent;
      width: 50%;
      margin-right: 40px;
      margin-top: 30px;

      h1 {
        font-size: 28px;
        color: #0b1f46;
        font-weight: 900;

        @media only screen and (max-width: 600px) {
          font-size: 16px;
        }
      }

      & .button-class {
        display: flex;
        justify-content: center;
      }

      & .requirements-container {
        display: flex;
        align-items: center;

        h6 {
          font-size: 23px;
          color: black;
          font-weight: 600;
        }
      }

      @media only screen and (max-width: 600px) {
        // margin-left: 15px;
        // margin-right: 15px;
        width: 100%;
      }
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column-reverse;
    }
  }

  & .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 1.2rem;
    padding-bottom: 12px;

    & .social-container {
      display: flex;

      a {
        display: flex;
        flex-wrap: wrap;
      }
    }

    @media only screen and (max-width: 600px) {
      justify-content: center;
    }
  }

  hr {
    border: 0;
    height: 2px;
    background-color: #e7e7e7;
    display: none;

    @media (max-width: 700px) {
      display: block;
    }
  }

  ::-webkit-scrollbar {
    width: 1px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;

export default AppStyle;
